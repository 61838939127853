import React from "react";
import "../Assets/Style/ITServices.css";
import { IMAGES } from "../Utils";
import { Animation } from "react-animate-style";
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useNavigate } from "react-router-dom";
const ITServices = () => {
  function serviceIconLeft() {
    var serviceIconLefts = document.querySelectorAll(".serviceIconLeft");

    for (var i = 0; i < serviceIconLefts.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = serviceIconLefts[i].getBoundingClientRect().top;
      var elementVisible = 100;

      if (elementTop < windowHeight - elementVisible) {
        serviceIconLefts[i].classList.add("active");
      } else {
        serviceIconLefts[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", serviceIconLeft);

  const navigate = useNavigate();
  return (
    <>
      <div className="it-services">
        <div className="container it-section">
          <div className="it-wrap">
            <div className="it-service-inner-wrapper">
              <p className="it-upper-title">DThrill IT Services</p>
              <AnimationOnScroll animateIn="animate__zoomIn">
                <p className="it-lower-title">
                  Services We Deliver We Provide Truly Prominent IT Solutions.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="it-main-wrap">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-1">
                <div className="single-service-wrap"  onClick={()=>navigate("/webdesign")}>
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <div className="single-service serviceIconLeft">
                      <img
                        src={IMAGES.ItWebDesign}
                        alt=""
                        className="service-img"
                      />
                    </div>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <div className="it-text">
                      <div>
                        <p className="service-heading">Web Design</p>
                        <p className="service-text">
                          You can find an original and organised website made by
                          our in-house designers. A clean and competent website
                          improves visitors' interest.
                        </p>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-1">
                <div className="single-service-wrap" onClick={()=>navigate("/webdevelopment")}>
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <div className="single-service serviceIconLeft">
                      <img
                        src={IMAGES.ItWebDev}
                        alt=""
                        className="service-img"
                      />{" "}
                    </div>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <div className="it-text">
                      <div>
                        <p className="service-heading">Web Development</p>
                        <p className="service-text">
                          Often website is first impression to a customer. We
                          provide UX and development services for web marketers
                          to improve your company and grow it at a remarkably
                          affordable rate.
                        </p>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-1">
                <div className="single-service-wrap" onClick={()=>navigate("/digitalmarketing")}>
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <div className="single-service serviceIconLeft">
                      <img
                        src={IMAGES.ItDegital}
                        alt=""
                        className="service-img"
                      />
                    </div>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <div className="it-text">
                      <div>
                        <p className="service-heading">Digital Marketing</p>
                        <p className="service-text">
                          Branding creates a visitor-remember your online
                          presence. Develop a plan for marketing and make the
                          content known for your brand with us.
                        </p>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-1">
                <div className="single-service-wrap" onClick={()=>navigate("#")}>
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <div className="single-service serviceIconLeft">
                      <img
                        src={IMAGES.ItMbDev}
                        alt=""
                        className="service-img"
                      />{" "}
                    </div>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <div className="it-text">
                      <div>
                        <p className="service-heading">
                          Mobile App Development
                        </p>
                        <p className="service-text">
                          We combine our creative instinct for innovative design
                          with our years of app development experience to
                          transform your idea into a mobile app that engages &
                          excites your audience.
                        </p>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-1">
                <div className="single-service-wrap" onClick={()=>navigate("/graphicdesign")}>
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <div className="single-service serviceIconLeft">
                      <img
                        src={IMAGES.ItGraphic}
                        alt=""
                        className="service-img"
                      />
                    </div>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <div className="it-text">
                      <div>
                        <p className="service-heading">Graphics Design</p>
                        <p className="service-text">
                          We develop incredible graphic styles that can launch
                          your brand in the stratosphere with photography,
                          diagrams, colour, typography and messaging.
                        </p>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-1">
                <div className="single-service-wrap" onClick={()=>navigate("#")}>
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <div className="single-service serviceIconLeft">
                      <img
                        src={IMAGES.ItTraining}
                        alt=""
                        className="service-img"
                      />
                    </div>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <div className="it-text">
                      <div>
                        <p className="service-heading">IT Corporate Training</p>
                        <p className="service-text">
                          We provide the most responsive and functional IT
                          design for companies and businesses worldwide.
                        </p>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ITServices;
