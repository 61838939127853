import React from "react";
import { IMAGES } from "../Utils";
import "../Assets/Style/CompitionWithDthrill.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const CompitionWithDthrill = () => {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  return (
    <>
      <div className="compitionSection">
        <div className="container">
          <div className="row">
            <div className="compitionSection-text">
              <div className="text reveal">
                <div className="compitition-inner">
                  <div className="dthrill">
                    DThrill - Welcome To IT Solutions
                  </div>
                  <AnimationOnScroll animateIn="animate__zoomIn">
                  <h2 className="goAhead">
                    Go Ahead Of Your
                    <br />
                    <strong>Competition With DTHRILL </strong>
                  </h2>
                  </AnimationOnScroll>
                  <p className="compition-para">
                    DThrill is a leading Digital Internet Marketing Agency based
                    in Pune, Maharashtra, that offers Web Development, Web
                    Design, Search Engine Optimization (SEO), Social Media
                    Marketing (SMM) & Pay Per Click (PPC) for small & medium
                    sized businesses.
                  </p>
                </div>
              </div>
              <div className="image compitionSection-image">
                <img src={IMAGES.CompititionSmall} className="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompitionWithDthrill;
