import React from "react";
import "../Assets/Style/Services.css";
import { IMAGES } from "../Utils";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import RegisterationPopUp from "../Components/RegisterationPopUp";

const Services = () => {

  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = ()=>{
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  }
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate= useNavigate();

  return (
    <>
     <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="separate-services-section">
        <div className="separate-services-heading">
          <h3>Services</h3>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="separate-service-outer-wrapper">
                <div className="separate-services-wrapper" onClick={()=>navigate("/webdesign")}>
                  <img
                    src={IMAGES.ServicesWebDesign}
                    alt=""
                    className="separate-services-img"
                  />
                  <div className="separate-service-inner-text-wrapper">
                    <img src={IMAGES.WebDesign2} alt="" />
                    <h3>Web Design</h3>
                    <p>
                      Our website design showcase illustrates our versatility
                      and commitment. We create affordable tailor-made design
                      solutions for businesses in a diverse range of industries.
                    </p>
                    <div className="services-konw-more">
                      <button onClick={()=>navigate("/webdesign")}>
                        Know More <FiArrowRight size={18} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="separate-service-outer-wrapper">
                <div className="separate-services-wrapper" onClick={()=>navigate("/webdevelopment")}>
                  <img
                    src={IMAGES.WebsiteDev}
                    alt=""
                    className="separate-services-img"
                  />
                  <div className="separate-service-inner-text-wrapper">
                    <img src={IMAGES.WebDev2} alt="" />
                    <h3>Web Development</h3>
                    <p>
                      It’s important to have your site performing well, and also
                      be accessible to users 24/7 (without technical
                      difficulties).
                    </p>
                    <div className="services-konw-more">
                      <button onClick={()=>navigate("/webdevelopment")}>
                        Know More <FiArrowRight size={18} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="separate-service-outer-wrapper">
                <div className="separate-services-wrapper" onClick={()=>navigate("/digitalmarketing")}>
                  <img
                    src={IMAGES.ServicesDM}
                    alt=""
                    className="separate-services-img"
                  />
                  <div className="separate-service-inner-text-wrapper">
                    <img src={IMAGES.Digital2} alt="" />
                    <h3>Digital Marketing</h3>
                    <p>
                      The most relavant websites for users search query should
                      be the top ranking search. You should focus on the
                      strategy on attracting people who will engage and convert.
                    </p>
                    <div className="services-konw-more">
                      <button onClick={()=>navigate("/digitalmarketing")}>
                        Know More <FiArrowRight size={18} />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="separate-service-outer-wrapper">
                <div className="separate-services-wrapper" onClick={()=>navigate("/graphicdesign")}>
                  <img
                    src={IMAGES.ServicesGD}
                    alt=""
                    className="separate-services-img"
                  />
                  <div className="separate-service-inner-text-wrapper">
                    <img src={IMAGES.Graphic2} alt="" />
                    <h3>Graphics Design</h3>
                    <p>
                      Companies use graphic design service to promote and sell
                      products as well as to make services more pleasing manner.
                    </p>
                    <div className="services-konw-more">
                      <button onClick={()=>navigate("/graphicdesign")}>
                        Know More <FiArrowRight size={18} />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="separate-service-outer-wrapper">
                <div className="separate-services-wrapper" onClick={()=>navigate("/mobileappdevelopment")}>
                  <img
                    src={IMAGES.MbAppDevelopmentHomeCard}
                    alt=""
                    className="separate-services-img"
                  />
                  <div className="separate-service-inner-text-wrapper">
                    <img src={IMAGES.ItMbDev} alt="" />
                    <h3>Mobile App Development</h3>
                    <p>
                      Respondent Web Design requires an appreciation of the web
                      content accessing technologies and networks and of
                      consumer usability needs.
                    </p>
                    <div className="services-konw-more">
                      <button onClick={()=>navigate("/mobileappdevelopment")}>
                        Know More <FiArrowRight size={18} />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="separate-service-outer-wrapper">
                <div className="separate-services-wrapper" onClick={()=>navigate("/it-training")}>
                  <img
                    src={IMAGES.ServicesITTraining}
                    alt=""
                    className="separate-services-img"
                  />
                  <div className="separate-service-inner-text-wrapper">
                    <img src={IMAGES.ItTraining} alt="" />
                    <h3>IT Corporate Training</h3>
                    <p>
                      Respondent Web Design requires an appreciation of the web
                      content accessing technologies and networks and of
                      consumer usability needs.
                    </p>
                    <div className="services-konw-more">
                      <button onClick={()=>navigate("/it-training")}>
                        Know More <FiArrowRight size={18} />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
