import React from "react";
import { IMAGES } from "../Utils";
import "../Assets/Style/HomeServices.css";
import { useNavigate } from "react-router-dom";

const HomeServices = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="services-height">
        <div className="container services-wrapper">
          <div className="row services">
            <div className="col">
              <div className="flipper-container">
                <div className="front face text-center servicebox ">
                  <img src={IMAGES.WebDesign2} className="services-icon" />
                  <p>Web Design</p>
                </div>
                <div
                  className="services-overlay"
                  onClick={() => navigate("/webdesign")}
                >
                  <div>
                    <img
                      src={IMAGES.WebDesignHomeCard}
                      alt=""
                      className="services-img"
                    />
                    <p>
                      You can find an original and organised website made by our
                      in-house designers. A clean and competent...
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="flipper-container">
                <div className="front face text-center servicebox">
                  <img src={IMAGES.WebDev2} className="services-icon" />
                  <p>Web Development</p>
                </div>
                <div
                  className="services-overlay"
                  onClick={() => navigate("/webdevelopment")}
                >
                  <div>
                    <img
                      src={IMAGES.WebDevHomeCard}
                      alt=""
                      className="services-img"
                    />
                    <p>
                      Often website is first impression to a customer. We
                      provide UX and development services for web... in-house
                      designers. A clean and competent...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="flipper-container">
                <div className="front face text-center servicebox">
                  <img src={IMAGES.Digital2} className="services-icon" />
                  <p>Digital Marketing</p>
                </div>
                <div
                  className="services-overlay"
                  onClick={() => navigate("/digitalmarketing")}
                >
                  <div>
                    <img
                      src={IMAGES.DigitalHomeCard}
                      alt=""
                      className="services-img"
                    />
                    <p>
                      We develop incredible graphic styles that can launch your
                      brand in the stratosphere with photography, diagrams,
                      colour, typography and...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="flipper-container">
                <div className="front face text-center servicebox">
                  <img src={IMAGES.Graphic2} className="services-icon" />
                  <p>Graphics Design</p>
                </div>
                <div
                  className="services-overlay"
                  onClick={() => navigate("/graphicdesign")}
                >
                  <div>
                    <img
                      src={IMAGES.GraphicsHomeCard}
                      alt=""
                      className="services-img"
                    />
                    <p>
                      Branding creates a visitor-remember your online presence.
                      Develop a plan for marketing and make the content...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="flipper-container">
                <div className="front face text-center servicebox">
                  <img src={IMAGES.ItMbDev} className="service-mb-icon" />
                  <p>Mobile App Development</p>
                </div>
                <div
                  className="services-overlay"
                  onClick={() => navigate("/graphicdesign")}
                >
                  <div>
                    <img
                      src={IMAGES.MbAppDevelopmentHomeCard}
                      alt=""
                      className="services-img"
                    />
                    <p>
                      Branding creates a visitor-remember your online presence.
                      Develop a plan for marketing and make the content...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeServices;
