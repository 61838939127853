import React from "react";
import { IMAGES } from "../Utils";
import "../Assets/Style/ViewBlog.css";

const ViewBlog = () => {
  return (
    <>
      <div className="viewBlogBody">
        <div className="row blogsBanner">
          <div className="blog-bg-banner">
            <div className="container blogText">
              <div>
                <h3>Blogs</h3>
                <h5>A directory of wonderful things</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="viewBlogSection">
            <div className="row">
              <div className="col-lg-8">
                <div className="blogImage">
                  <img src={IMAGES.BlogInnerDM} alt=" img for blog" />
                </div>
                <div className="profile">
                  <ul>
                    <li>DThrill Software Solutions LLP</li>
                    <li>Web Design</li>
                  </ul>
                </div>

                <div className="blogTitle">
                  <h3>Digital marketing is essential for small companies</h3>
                </div>

                <div className="blogDescription">
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don’t
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum. You need to be sure there isn’t
                    anything embarrassing hidden in the middle of text. All the
                    Lorem Ipsum generators on the Internet tend toitrrepeat
                    predefined chunks. Highly at nisi nam vestibulum ut eget
                    vitae sed. Potenti aliquam feugiat proin facilisi
                    calibrated. for us if we carry yesterday’s burden over again
                    today, and then add the burden of the morrow before we are
                    required to bear it All these digital elements and projects
                    aim to enhance
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="recentStorySection">
                  <div>
                    <div className="recentStory">
                      <h3>Recent Stories</h3>
                    </div>

                    <div className="recentStoryList">
                      <div className="blogImage">
                        <img src={IMAGES.Compition} alt=" img for blog" />
                      </div>
                      <div className="recentBlogInfo">
                        <p>Adverto Just Pushes Deeper Into Agency</p>
                        <p>Apr 14, 2023</p>
                      </div>
                    </div>
                    <div className="recentStoryList">
                      <div className="blogImage">
                        <img src={IMAGES.Compition} alt=" img for blog" />
                      </div>
                      <div className="recentBlogInfo">
                        <p>Adverto Just Pushes Deeper Into Agency</p>
                        <p>Apr 14, 2023</p>
                      </div>
                    </div>
                  </div>
                  <div className="categories">
                    <div className="recentStory">
                      <h3>Categories</h3>
                    </div>
                    <ul>
                      <li>Agency</li>
                      <li>comuter</li>
                      <li>development</li>
                      <li>designer</li>
                      <li>Graphic Design</li>
                      <li>Digital Marketing</li>
                    </ul>
                  </div>

                  <div className="Tags">
                    <div className="recentStory">
                      <h3>Tags</h3>
                    </div>
                    <div className="row tagsList">
                        <div className="col-lg-12">
                          <a>Agency</a>
                        </div>
                      <div className="col-lg-12">
                        <a>Comuter</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Development</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Designer</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Graphics Design</a>
                      </div>
                      <div className="col-lg-12">
                        <a>Digital Marketing</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewBlog;
