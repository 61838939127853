import React from "react";
import "../Assets/Style/OurCoreValues.css";
import { IMAGES } from "../Utils";
import { AnimationOnScroll } from "react-animation-on-scroll";

const OurCoreValues = () => {
  return (
    <>
      <section className="coreValue-section">
        <div className="coreValue-title">
          <div className="coreValue-heading">
            <p className="upper-text">DThrill - Values</p>
            <AnimationOnScroll animateIn="animate__zoomIn">
              <h3>
                Our Core Values
              </h3>
            </AnimationOnScroll>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="core-value">
                <div className="value-card value-transparency">
                  <div className="Img-title">
                    <img
                      src={IMAGES.ValueTransparency}
                      className="value-icon"
                    />
                  </div>
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <h3>Transparency</h3>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <p className="value-text">
                      It is important that our client's know exactly what they
                      are paying for. We provide detailed information about the
                      work we undertake on their behalf.
                    </p>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="core-value">
                <div className="value-card value-knowledge">
                  <div className="Img-title">
                    <img src={IMAGES.ValueKnowledge} className="value-icon" />
                  </div>
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <h3>Knowledge</h3>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <p className="value-text">
                      We commit significant time and attention to continuing
                      professional development. The world in which we work
                      changes rapidly and we can only be the best through
                      constant attention to research, knowledge gathering and
                      training.
                    </p>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="core-value">
                <img src={IMAGES.OurCoreValueImg1} alt="" className="core-value-img" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="core-value">
                <img src={IMAGES.OurCoreValueImg2} alt="" className="core-value-img" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="core-value">
                <div className="value-card value-service">
                  <div className="Img-title">
                    <img src={IMAGES.ValueService} className="value-icon" />
                  </div>
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <h3>Service</h3>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <p className="value-text">
                      We welcome regular contact with our clients. All of our
                      work is undertaken by our dedicated in-house teams and we
                      view all our client relationships as partnerships. We
                      thrive on great communication.
                    </p>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="core-value">
                <div className="value-card value-value">
                  <div className="Img-title">
                    <img src={IMAGES.ValueValue} className="value-icon" />{" "}
                  </div>
                  <AnimationOnScroll animateIn="animate__fadeIn">
                    <h3>Value</h3>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <p className="value-text">
                      We are committed to offering the very best value. Clients
                      will only pay for the services required and we consider
                      all aspects of each project - technology, development,
                      training and ongoing service to ensure that we support our
                      clients in making the best and most efficient choices.
                    </p>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurCoreValues;
