import React, { useState, useEffect } from "react";
import SliderSection from "../Sections/SliderSection";
import HomeServices from "../Sections/HomeServices";
import ITServices from "../Sections/ITServices";
import OurCoreValues from "../Sections/OurCoreValues";
import HowWeWorks from "../Sections/HowWeWorks";
import CompitionWithDthrill from "../Sections/CompitionWithDthrill";
import Testimonials from "../Sections/Testimonials";
import WeSpecializeIn from "../Sections/WeSpecializeIn";
import CapableShowCase from "../Sections/CapableShowCase";
import VideoSection from "../Sections/VideoSection";
import Careers from "../Sections/Careers";
import Opportunities from "../Sections/Opportunities";
import RegisterationPopUp from "../Components/RegisterationPopUp";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <SliderSection />
      <HomeServices />
      <CompitionWithDthrill />
      <ITServices />
      <WeSpecializeIn />
      <OurCoreValues />
      <CapableShowCase />
      <HowWeWorks />
      <VideoSection />
      <Careers />
      <Testimonials />
      <Opportunities />
      
    </>
  );
};

export default Home;
