import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../Assets/Style/RegisterationPopUp.css";
import { MdClose } from "react-icons/md";
import { dthrilliumail } from "../config/api";

const RegisterationPopUp = ({ show, setShow, handleClose }) => {
  // function handleSubmit(e) {
  //   setShow(false);
  // }
  const [state, setState] = useState({
    FirstName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    SelectOption: "",
    Service: "",
    Products: "",
    Training: "",
    Message: "",
  });
  const [service, setService] = useState(false);
  const [product, setProduct] = useState(false);
  const [training, setTraining] = useState(false);
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (e.target.value === "service") {
      setService(true);
      setProduct(false);
      setTraining(false);

      setState((state.Products = ""), (state.Training = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.value === "products") {
      setProduct(true);
      setTraining(false);
      setService(false);

      setState((state.Service = ""), (state.Training = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
    if (e.target.value === "training") {
      setProduct(false);
      setTraining(true);
      setService(false);

      setState((state.Service = ""), (state.Products = ""));
      setState({ ...state, [e.target.name]: e.target.value });
    }
    console.log("handdle hcnage--->", state);
  };
  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const Mobilenumber = /^[7-9][0-9]/;

    if (!state.FirstName) {
      IsValid = false;
      errors["FirstName"] = "Full Name Is Required";
    } else if (state.FirstName.length < 2) {
      IsValid = false;
      errors["FirstName"] = "Must Be 15 Characters ";
    } else if (!state.FirstName.match(regex_name)) {
      IsValid = false;
      errors["FirstName"] = "Please Enter Valid Name";
    } else {
      errors["FirstName"] = "";
    }
    if (!state.LastName) {
      IsValid = false;
      errors["LastName"] = "Last Name Is Required";
    } else if (state.LastName.length < 2) {
      IsValid = false;
      errors["LastName"] = "Must Be 15 Characters ";
    } else if (!state.LastName.match(regex_name)) {
      IsValid = false;
      errors["LastName"] = "Please Enter Valid Name";
    } else {
      errors["LastName"] = "";
    }

    if (!state.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile number Is Required";
    } else if (state.Mobile.length < 10 || state.Mobile.length > 10) {
      IsValid = false;
      errors["Mobile"] = "Mobile Number Must Have 10 Digits";
    } else if (!state.Mobile.match(Mobilenumber)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }

    if (!state.EmailID) {
      IsValid = false;
      errors["EmailID"] = "Email ID Is Required";
    } else if (!state.EmailID.match(regex_email)) {
      IsValid = false;
      errors["EmailID"] = "Please Enter valid Email id";
    } else {
      errors["EmailID"] = "";
    }

    if (!state.SelectOption) {
      IsValid = false;
      errors["SelectOption"] = "Please Select Option ";
    } else {
      errors["SelectOption"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };
  const clearState = () => {
    setState({
      ContactID: 0,
      FirstName: "",
      LastName: "",
      EmailID: "",
      Mobile: "",
      SelectOption: "",
      Service: "",
      Products: "",
      Training: "",
      Message: "",
      CreatedBy: 0,
    });
  };
  const handleSubmit = async () => {
    console.log("submit-->", state);
    if (validate()) {
      await dthrilliumail(state).then((res) => {
        console.log("contact--->", res[0]);
        if (res.length == 1) {
          alert("Thank You..!! Will Get Back To YOu soon..!!");
          clearState();
        }
      });
    } else {
      alert("Please Enter Proper Details.!");
    }
  };

  return (
    <>
      <Modal className="reg-modal-content" show={show} onHide={handleClose}>
        <Modal.Header className="border-bottom-0">
          <Modal.Title className="reg-title">Quick Enquiry</Modal.Title>
          <button
            type="button"
            className="quick-equiry-btn-close "
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <MdClose size={25} className="quick-equiry-btn-close-icon" />
          </button>
        </Modal.Header>
        <div className="pt-0 reg-modal-body">
          <div className="w-100 mb-3 px-2">
            {/* <label className="mb-1">Full Name</label> */}
            <input
              type="text"
              className="reg-form-control"
              placeholder="First Name"
              autoComplete="off"
              name="FirstName"
              value={state.FirstName}
              onChange={handleChange}
            />
            {state.errors ? (
              <p className="req-errors">{state.errors.FirstName}</p>
            ) : null}
          </div>
          <div className="w-100 mb-3 px-2">
            {/* <label className="mb-1">Full Name</label> */}
            <input
              type="text"
              className="reg-form-control"
              placeholder="Last Name"
              autoComplete="off"
              name="LastName"
              value={state.LastName}
              onChange={handleChange}
            />
            {state.errors ? (
              <p className="req-errors">{state.errors.FirstName}</p>
            ) : null}
          </div>
          <div className="w-100 mb-3 px-2">
            {/* <label className="mb-1">Mobile Number</label> */}
            <input
              type="text"
              className="reg-form-control"
              placeholder="Mobile Number"
              autoComplete="off"
              name="Mobile"
              value={state.Mobile}
              onChange={handleChange}
            />
            {state.errors ? (
              <p className="req-errors">{state.errors.Mobile}</p>
            ) : null}
          </div>
          <div className="w-100 mb-3 px-2">
            {/* <label className="mb-1">Email ID</label> */}
            <input
              type="text"
              className="reg-form-control"
              placeholder="Email ID"
              autoComplete="off"
              name="EmailID"
              value={state.EmailID}
              onChange={handleChange}
            />
          </div>
          {state.errors ? (
            <p className="errors">{state.errors.EmailID}</p>
          ) : null}
          <div className="row px-2">
            <div className="col-lg-12">
              <div className="reg-pop-inputWrapper">
                {/* <label className="mb-1">Select</label> */}
                <select
                  className="reg-form-control"
                  name="SelectOption"
                  onChange={handleChange}
                  value={state.SelectOption}
                >
                  <option>-- Select --</option>
                  <option value="service">Services</option>
                  <option value="products">Products</option>
                  <option value="training">IT Training</option>
                </select>
              </div>
            </div>
          </div>
          {service ? (
            <div className="row px-2">
              <div className="col-lg-12">
                <div className="reg-pop-inputWrapper">
                  {/* <label className="mb-1">Select Service</label> */}
                  <select
                    className="reg-form-control"
                    name="Service"
                    value={state.Service}
                    onChange={handleChange}
                  >
                    <option value="selectType">-- Select Services --</option>
                    <option value="web design">Web Design</option>
                    <option value="web development">Web development</option>
                    <option value="digital marketing">Digital Marketing</option>
                    <option value="graphics design">Graphics Design</option>
                  </select>
                </div>
              </div>
            </div>
          ) : null}

          {product ? (
            <div className="row px-2">
              <div className="col-lg-12">
                <div className="reg-pop-inputWrapper">
                  {/* <label className="mb-1">Select Product</label> */}
                  <select
                    className="reg-form-control"
                    name="Products"
                    value={state.Products}
                    onChange={handleChange}
                  >
                    <option value="selectType">-- Select Products --</option>
                    <option value=" School Management">
                      School Management
                    </option>
                    <option value="SPA Management">SPA Management</option>
                    <option value="Hospital Management">
                      Hospital Management
                    </option>
                    <option value="Inventory Management">
                      Inventory Management
                    </option>
                    <option value=" Payroll Management">
                      Payroll Management
                    </option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
            </div>
          ) : null}

          {training ? (
            <div className="row px-2">
              <div className="col-lg-12">
                <div className="reg-pop-inputWrapper">
                  {/* <label className="mb-1">Select Training</label> */}
                  <select
                    className="reg-form-control"
                    name="Training"
                    value={state.Training}
                    onChange={handleChange}
                  >
                    <option value="selectType">-- Select Training --</option>
                    <option value="digital marketing">
                      Web Design Training
                    </option>
                    <option value="graphics design">
                      Web Development Training
                    </option>
                    <option value="web design">
                      Digital Marketing Training
                    </option>
                    <option value="web development">
                      Graphics Designing Training
                    </option>
                    <option value="staffing">Staffing</option>
                    <option value="recruitment">Recruitment</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
            </div>
          ) : null}
          <div className="w-100 mb-3 px-2">
            {/* <label className="mb-1">Description</label> */}
            <textarea
              className="reg-form-control"
              placeholder="Message"
              rows={2}
              autoComplete="off"
              name="Message"
              value={state.Message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="w-100 px-2 enquire-btn-wrapper">
            <button className="btn btn-enquire" onClick={handleSubmit}>
              Submit Enquiry
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RegisterationPopUp;
