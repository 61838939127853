import React from "react";
import { IMAGES } from "../Utils";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import RegisterationPopUp from "../Components/RegisterationPopUp";

const Products = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="separate-services-section">
        <div className="separate-services-heading">
          <h3>Products</h3>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="separate-service-outer-wrapper">
                <div
                  className="separate-services-wrapper"
                  onClick={() => navigate("#")}
                >
                  <img
                    src={IMAGES.ProductsSchoolImg}
                    alt=""
                    className="separate-services-img"
                  />
                  <div className="separate-service-inner-text-wrapper">
                    <img src={IMAGES.ProductsSchoolIcon} alt="" />
                    <h3>School Management</h3>
                    <p>
                      Our website design showcase illustrates our versatility
                      and commitment. We create affordable tailor-made design
                      solutions for businesses in a diverse range of industries.
                    </p>
                    <div className="services-konw-more">
                      <button onClick={() => navigate("#")}>
                        Know More <FiArrowRight size={18} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="separate-service-outer-wrapper">
                <div
                  className="separate-services-wrapper"
                  onClick={() => navigate("#")}
                >
                  <img
                    src={IMAGES.ProductsSpaImg}
                    alt=""
                    className="separate-services-img"
                  />
                  <div className="separate-service-inner-text-wrapper">
                    <img src={IMAGES.ProductsSpaIcon} alt="" />
                    <h3>SPA Management</h3>
                    <p>
                      It’s important to have your site performing well, and also
                      be accessible to users 24/7 (without technical
                      difficulties).
                    </p>
                    <div className="services-konw-more">
                      <button onClick={() => navigate("#")}>
                        Know More <FiArrowRight size={18} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="separate-service-outer-wrapper">
                <div
                  className="separate-services-wrapper"
                  onClick={() => navigate("#")}
                >
                  <img
                    src={IMAGES.ProductsHospitalImg}
                    alt=""
                    className="separate-services-img"
                  />
                  <div className="separate-service-inner-text-wrapper">
                    <img src={IMAGES.ProductsHospitalIcon} alt="" />
                    <h3>Hospital Management</h3>
                    <p>
                      The most relavant websites for users search query should
                      be the top ranking search. You should focus on the
                      strategy on attracting people who will engage and convert.
                    </p>
                    <div className="services-konw-more">
                      <button>
                        Know More <FiArrowRight size={18} />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="separate-service-outer-wrapper">
                <div className="separate-services-wrapper">
                  <img
                    src={IMAGES.ProductsInventoryImg}
                    alt=""
                    className="separate-services-img"
                  />
                  <div className="separate-service-inner-text-wrapper">
                    <img src={IMAGES.ProductsInventoryIcon} alt="" />
                    <h3>Inventory Management</h3>
                    <p>
                      Companies use graphic design service to promote and sell
                      products as well as to make services more pleasing manner.
                    </p>
                    <div className="services-konw-more">
                      <button>
                        Know More <FiArrowRight size={18} />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="separate-service-outer-wrapper">
                <div className="separate-services-wrapper">
                  <img
                    src={IMAGES.ProductsPayrollImg}
                    alt=""
                    className="separate-services-img"
                  />
                  <div className="separate-service-inner-text-wrapper">
                    <img src={IMAGES.ProductsPayrollIcon} alt="" />
                    <h3>Payroll Management</h3>
                    <p>
                      Respondent Web Design requires an appreciation of the web
                      content accessing technologies and networks and of
                      consumer usability needs.
                    </p>
                    <div className="services-konw-more">
                      <button>
                        Know More <FiArrowRight size={18} />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
