import React from "react";
import "../Assets/Style/Opportunities.css";
import { MdArrowForward } from "react-icons/md";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useNavigate } from "react-router-dom";

const Opportunities = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="Opportunities">
        <div className="container-fluid OpportunitiesSection">
          <div className="row">
            <div className="col-lg-6 OpportunitiesLeft">
            <AnimationOnScroll animateIn="animate__zoomIn">
              <h3 className="title">
                Assess your business potentials and find opportunities {" "}
                <strong>for bigger success</strong>
              </h3>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-6 OpportunitiesRight">
              <div className="btnLetsTalk">
                <button className="animated-button1" onClick={()=>navigate("/contact")}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <p>Let's Talk </p>
                  <MdArrowForward size={20} />
                </button>
              </div>
              <div className="btnGetInfo">
                <button className="animated-button1">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <p>Get Info </p>
                  <MdArrowForward size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Opportunities;
