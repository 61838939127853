import React from "react";
import "../Assets/Style/Testimonials.css";
import { Carousel } from "react-bootstrap";
import { IMAGES } from "../Utils";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Testimonials = () => {
  return (
    <>
      <div className="testimonials-section">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="test-main-wrapper">
              <div className="test-wrapper">
                <div className="test-header">
                  <h5>Testimonials</h5>
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <h3>We help to achieve mutual goals.</h3>
                  </AnimationOnScroll>
                </div>
                <div className="test-reviews">
                  <Carousel indicators={false} variant="dark">
                    <Carousel.Item>
                      <img
                        className="test-profile"
                        src={IMAGES.Sunil}
                        alt="First slide"
                      />
                      <div className="test-review-wrap">
                        <h5>Sunil Shelke</h5>
                        <p className="designation">Entrepreneur</p>
                        <p className="review-para">
                        I had a fantastic experience with the services provided by DThrill Service Provider. Their team was highly professional and knowledgeable, and they truly understood our business goals and target audience. They developed a comprehensive digital marketing strategy that included SEO, social media marketing, and paid advertising.
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="test-profile"
                        src={IMAGES.Aashish}
                        alt="First slide"
                      />
                      <div className="test-review-wrap">
                        <h5>Aashish Dhole</h5>
                        <p className="designation">
                          CEO (Kalyani Film Production){" "}
                        </p>
                        <p className="review-para">
                        The graphic design service provided by Dthrill Provider exceeded my expectations. Their team demonstrated exceptional creativity and attention to detail in delivering visually stunning designs for our branding and marketing materials. From the initial consultation to the final delivery, the communication and collaboration were seamless.  They took the time to understand our brand identity and objectives, resulting in designs that perfectly captured our vision.
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="test-profile"
                        src={IMAGES.Sandeep}
                        alt="First slide"
                      />
                      <div className="test-review-wrap">
                        <h5>Sandeep Shelke</h5>
                        <p className="designation">CEO</p>
                        <p className="review-para">
                          DThrill Software Solutions LLP has given us a very
                          professional and friendly service throughout our
                          Branding services, website design to our ongoing SEO
                          services. The SEO team work hard to help get the most
                          from our website. We have seen an progress in our
                          business. We highly recommend Dthrill Software
                          Solutions LLP, Pune for Branding, SEO and website
                          design services.
                        </p>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="test-right-section">
              <img src={IMAGES.TestimonialRight} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
