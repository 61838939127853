import React, { useState, useEffect } from "react";
import "../Assets/Style/AboutUs.css";
import { IMAGES } from "../Utils";
import { FiArrowRight } from "react-icons/fi";
import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";
import { AnimationOnScroll } from "react-animation-on-scroll";
import CountUp from "react-countup/";
import ScrollTrigger from "react-scroll-trigger/";
import RegisterationPopUp from "../Components/RegisterationPopUp";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const [showModal, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => setShowModal(true), 50000);
  };
  useEffect(() => {
    setTimeout(() => setShowModal(true), 50000);
  }, []);

  function reveal() {
    var aboutcards = document.querySelectorAll(".about-card");

    for (var i = 0; i < aboutcards.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = aboutcards[i].getBoundingClientRect().top;
      aboutcards[i].classList.add("active");
    }
  }

  const [counterOn, setCounterOn] = useState(false);

  window.addEventListener("scroll", reveal);

  const whyChoose = [
    { title: "Best Practical Training Course" },
    { title: "Well Qualified Trainers" },
    { title: "Industry level Training and Live Project" },
    { title: "Well structured modules for each course." },
    { title: "We provide 100% placement assistance for jobs." },
    { title: "Individual attention to each student." },
    { title: "Part Time and Full Time Classes" },
    { title: "Highest quality Courseware" },
    { title: "Continuous Assessments" },
    { title: "Professional Instruction" },
    { title: "Hands-on Work Experience" },
    { title: "Fast Training with a Career Focus" },
    { title: "Affordable Fees" },
    { title: "Quality Education" },
    { title: "Practical Sessions" },
  ];

  const navigate = useNavigate();

  return (
    <>
      <RegisterationPopUp
        show={showModal}
        setShow={setShowModal}
        handleClose={handleClose}
      />
      <div className="about-main-section">
        {/* top section */}
        <div className="container about-top-section">
          <p className="dev-thrill">DEVELOPERS THRILL</p>
          <img src={IMAGES.AboutBanner} alt="" className="about-top-img" />
          <div className="about-small-card">
            <p className="dthrill">DTHRILL</p>
            <p className="soft-sol">SOFTWARE SOLUTION</p>
            <p className="about-heading-text">
              Our Expertise. Know more about what we do
            </p>
          </div>
          <p className="dev-thrill">DEVELOPERS THRILL</p>
        </div>

        {/* Welcome para section */}
        <div className="container about-welcome-section">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="about-services-cards">
                <div className="d-flex">
                  <div className="about-main-card">
                    <div className="about-card about-web-dev">
                      <div className="">
                        <img
                          src={IMAGES.WebDev2}
                          alt=""
                          className="about-services-img"
                        />
                        <p className="about-serv-name">Web Development</p>
                      </div>
                    </div>
                  </div>
                  <div className="about-main-card">
                    <div className="about-card about-web-design">
                      <div>
                        <img
                          src={IMAGES.Digital2}
                          alt=""
                          className="about-services-img"
                        />
                        <p className="about-serv-name">Digital Marketing </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="about-main-card">
                    {/* <AnimationOnScroll
                      animateIn="animate__swing"
                      initiallyVisible={true}
                      //
                    > */}
                    <div className="about-card about-graphic">
                      <div>
                        <img
                          src={IMAGES.ItMbDev}
                          alt=""
                          className="about-services-img"
                        />
                        <p className="about-serv-name">
                          Mobile App Development
                        </p>
                      </div>
                    </div>
                    {/* </AnimationOnScroll> */}
                  </div>
                  <div className="about-main-card">
                    {/* <AnimationOnScroll
                      animateIn="animate__swing"
                      initiallyVisible={true}
                      //
                    > */}
                    <div className="about-card about-digital">
                      <div>
                        <img
                          src={IMAGES.Graphic2}
                          alt=""
                          className="about-services-img"
                        />
                        <p className="about-serv-name">Graphics Designing</p>
                      </div>
                    </div>
                    {/* </AnimationOnScroll> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12">
              <p className="wel-sub-text">DThrill - Welcome To IT Solutions</p>
              <AnimationOnScroll animateIn="animate__zoomIn">
                <p className="wel-main-text">We Run All Kinds Of</p>
                <p className="wel-bold-text">
                  IT Services That Now Your Success.
                </p>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInUp" offset={50}>
                <p className="about-para">
                  DThrill Software Solutions LLP are a full-service web design
                  agency with a dedicated focus on creating websites that are
                  Responsive, Secure and Results Driven. We utilise the latest
                  technology which in turn guarantees fantastic results for the
                  clients we help and support. We pride ourselves in a great
                  work ethic, integrity and most importantly end-results. Web
                  Choice India have been trading for over 10 years, 4 of which
                  as a Limited Company, in this time our experienced team of
                  designers have been able to create hundreds of stunning,
                  sometimes award-winning, web designs in multiple verticals;
                  giving our clients the leading edge and exponential growth in
                  an ever changing online marketplace.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
        </div>

        {/* vision mission section */}
        <div className="vision-mission-section">
          <div className="row mission-vision-flex">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="mission-wrapper">
                <div className="mission-section">
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <h3>Mission</h3>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <p>
                      Our mission is to enable businesses with cutting-edge
                      digital solutions to drive growth through our innovative
                      digital marketing services such as Web Development, Mobile
                      App Development, Graphics Design, Consultancy and esource
                      augmentation to help them exceed client expectations, and
                      amplify their online presence.
                    </p>
                  </AnimationOnScroll>
                </div>{" "}
                <div className="vision-section">
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <h3>Vision</h3>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <p>
                      Our vision is to become India’s top digital transformation
                      company and a leader in the ever-evolving digital
                      marketplace.
                    </p>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="vision-wrapper">
                {/* <div className="vision-section">
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <h3>Vision</h3>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInUp">
                    <p>
                      DThrill Software Solution's mission is to add value to
                      your company by developing creative products and providing
                      one-of-a-kind services that process quality and customer
                      satisfaction. To establish strong and long-term
                      relationships, our dedicated teams pay close attention to
                      the needs and requirements of our clients. Our team works
                      together to ensure that our clients' needs are met,
                      resulting in profitable growth for a variety of companies.
                    </p>
                  </AnimationOnScroll>
                </div> */}
                <img src={IMAGES.AboutMissionVision} alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* Capabilities section */}
        <div className="container capability-section">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="capability-wrapper-left">
                <div className="challenge-title-section">
                  <p className="challenge-small-heading">Capabilities</p>
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <h3 classNmae="challenge-main-title">
                      We help to create strategies, design & development.
                    </h3>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="capability-wrapper-right">
                    <div className="challenge-title-section">
                      <p className="challenge-small-heading">Stragergy</p>
                      <AnimationOnScroll animateIn="animate__fadeInUp">
                        <h4 classNmae="challenge-sub-title">
                          Brand Strategy & Art Direction
                        </h4>
                      </AnimationOnScroll>
                    </div>
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                      <div className="capabitity-para">
                        <p>
                          Creating a higher spacing and how people move through
                          a unique and impactful campaign.
                        </p>
                      </div>
                    </AnimationOnScroll>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="capability-wrapper-right">
                    <div className="challenge-title-section">
                      <p className="challenge-small-heading">Design</p>
                      <AnimationOnScroll animateIn="animate__fadeInUp">
                        <h4 classNmae="challenge-sub-title">
                          UX/UI Design & Website/App Design
                        </h4>
                      </AnimationOnScroll>
                    </div>
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                      <div className="capabitity-para">
                        <p>
                          Get a regulatory oneven an enterprises such she and
                          the got the did attributing and pushed.
                        </p>
                      </div>
                    </AnimationOnScroll>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="capability-wrapper-right">
                    <div className="challenge-title-section">
                      <p className="challenge-small-heading">Production</p>
                      <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        offset={50}
                      >
                        <h4 classNmae="challenge-sub-title">
                          Typography & Video Production
                        </h4>
                      </AnimationOnScroll>
                    </div>
                    <AnimationOnScroll
                      animateIn="animate__fadeInUp"
                      offset={50}
                    >
                      <div className="capabitity-para">
                        <p>
                          Spaces of each debt in the digital world can help you
                          with overall simplest authentic.
                        </p>
                      </div>
                    </AnimationOnScroll>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="capability-wrapper-right">
                    <div className="challenge-title-section">
                      <p className="challenge-small-heading">Campaigns</p>
                      <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        offset={20}
                      >
                        <h4 classNmae="challenge-sub-title">
                          Promo Campaigns & Content Creation
                        </h4>
                      </AnimationOnScroll>
                    </div>
                    <AnimationOnScroll
                      animateIn="animate__fadeInUp"
                      offset={20}
                    >
                      <div className="capabitity-para">
                        <p>
                          Making an email campaigns to achieve a real impact.
                        </p>
                      </div>
                    </AnimationOnScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Why Choose us */}
        <div className="why-choose">
          <div className="why-choose-section">
            <div className="why-choose-outer-wrapper">
              <div className="why-choose-img-wrapper">
                <img src={IMAGES.AboutWhyChooseUs} alt="" />
              </div>
            </div>
            <div className="why-choose-outer-wrapper">
              <img src={IMAGES.AboutBubble} alt="" className="bubble" />
              <div className="why-choose-content-wrapper">
                <h4>Why Choose Us ?</h4>

                <ul className="row">
                  {whyChoose?.map((why, idx) => (
                    <li className="col-lg-6 col-md-12 col-sm-12">
                      <div>
                        <MdKeyboardArrowRight size={20} />
                      </div>
                      <p>{why.title} </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Approach section */}
        <div className="container approach-section">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="approach-wrapper">
                <div>
                  <div className="approach-title-section">
                    <p>Approach</p>
                    <AnimationOnScroll animateIn="animate__zoomIn">
                      <h3>
                        We’re changing the way people think of UI/UX creation.
                      </h3>
                    </AnimationOnScroll>
                  </div>
                  <div className="approach-para">
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                      <p>
                        A full-stack structure for our working{" "}
                        <span>workflow processes</span>, were from the funny the
                        century initial all the made, have spare to negatives.
                      </p>
                    </AnimationOnScroll>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="approach-wrapper">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="about-mb">
                    <img src={IMAGES.AboutMb1} alt="" />
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="approach-wrapper">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="about-mb">
                    <img src={IMAGES.AboutMb2} alt="" />
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="ststc-title-section">
                <h5>Statistics</h5>
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <h3>Project by the numbers.</h3>
                </AnimationOnScroll>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="statistics-wrapper">
                    <div>
                      <div className="ststc-icon-text">
                        {/* <TbLayersIntersect size={50} /> */}
                        <div className="ststc-icon-img-wrapper">
                          <div className="ststc-inner-box">
                            <img src={IMAGES.AboutWebMb} alt="" />
                          </div>
                        </div>
                        <div>
                          <ScrollTrigger
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(false)}
                          >
                            <p className="">
                              {counterOn && (
                                <CountUp
                                  start={0}
                                  end={90}
                                  duration={1}
                                  delay={0}
                                />
                              )}
                              +
                            </p>
                          </ScrollTrigger>
                        </div>
                      </div>
                      <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div className="ststc-title">
                          <p>Web apps designs</p>
                        </div>
                        <div className="ststc-para">
                          <p>
                            Creating a full range UI/UX apps accross the whole
                            platform.
                          </p>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="statistics-wrapper">
                    <div>
                      <div className="ststc-icon-text">
                        {/* <TbLayersIntersect size={50} /> */}
                        <div className="ststc-icon-img-wrapper">
                          <div className="ststc-inner-box">
                            <img src={IMAGES.AboutAward} alt="" />
                          </div>
                        </div>
                        <ScrollTrigger
                          onEnter={() => setCounterOn(true)}
                          onExit={() => setCounterOn(false)}
                        >
                          <p className="">
                            {counterOn && (
                              <CountUp
                                start={0}
                                end={50}
                                duration={1}
                                delay={0}
                              />
                            )}
                            +
                          </p>
                        </ScrollTrigger>
                      </div>
                      <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div className="ststc-title">
                          <p>Awards for digital innovation</p>
                        </div>
                        <div className="ststc-para">
                          <p>
                            We have strict intentions to completely change the
                            way of UI/UX creation.
                          </p>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="statistics-wrapper">
                    <div>
                      <div className="ststc-icon-text">
                        {/* <TbLayersIntersect size={50} /> */}
                        <div className="ststc-icon-img-wrapper">
                          <div className="ststc-inner-box">
                            <img src={IMAGES.AboutAPI} alt="" />
                          </div>
                        </div>
                        <ScrollTrigger
                          onEnter={() => setCounterOn(true)}
                          onExit={() => setCounterOn(false)}
                        >
                          <p className="">
                            {counterOn && (
                              <CountUp
                                start={0}
                                end={30}
                                duration={1}
                                delay={0}
                              />
                            )}
                            +
                          </p>
                        </ScrollTrigger>
                      </div>
                      <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div className="ststc-title">
                          <p> API Integrations</p>
                        </div>
                        <div className="ststc-para">
                          <p>
                            Software-enabled platforms to completely change the
                            way of UI/UX iterations.
                          </p>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="statistics-wrapper">
                    <div>
                      <div className="ststc-icon-text">
                        {/* <TbLayersIntersect size={50} /> */}
                        <div className="ststc-icon-img-wrapper">
                          <div className="ststc-inner-box">
                            <img src={IMAGES.AboutMb} alt="" />
                          </div>
                        </div>
                        <ScrollTrigger
                          onEnter={() => setCounterOn(true)}
                          onExit={() => setCounterOn(false)}
                        >
                          <p className="">
                            {counterOn && (
                              <CountUp
                                start={0}
                                end={200}
                                duration={1}
                                delay={0}
                              />
                            )}
                            +
                          </p>
                        </ScrollTrigger>
                      </div>
                      <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div className="ststc-title">
                          <p>Mobile apps designs</p>
                        </div>
                        <div className="ststc-para">
                          <p>
                            Creating a full range UI/UX apps accross the whole
                            platform.
                          </p>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* what we do section */}
        <div className="what-we-do-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="what-we-do-wrapper">
                  <div>
                    <div className="what-we-do-title">
                      <p>WHAT WE DO</p>
                      <AnimationOnScroll animateIn="animate__zoomIn">
                        <h3>
                          Does more then ever platform to achieve goal stages.
                        </h3>
                      </AnimationOnScroll>
                    </div>
                    <AnimationOnScroll animateIn="animate__fadeIn" offset={50}>
                      <div className="what-we-do-para">
                        <p>
                          Our clients describe us as a product team which{" "}
                          <span>creates amazing UI/UX </span>experiences, by
                          crafting top-notch user experience.
                        </p>
                      </div>
                    </AnimationOnScroll>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="what-we-do-wrapper">
                      <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        offset={50}
                      >
                        <div className="what-we-do-main">
                          <img src={IMAGES.AboutExprnc} alt="" />
                          <div className="what-we-do-content">
                            <h5>Years Of Experience</h5>
                            <p>
                              We are proudly serving our clients globally for
                              more than a decade.
                            </p>
                          </div>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="what-we-do-wrapper">
                      <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        offset={50}
                      >
                        <div className="what-we-do-main">
                          <img src={IMAGES.AboutCost} alt="" />
                          <div className="what-we-do-content">
                            <h5> Cost-Effective</h5>
                            <p>
                              We provide cost-effective engineering services to
                              our clients globally.
                            </p>
                          </div>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="what-we-do-wrapper">
                      <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        offset={50}
                      >
                        <div className="what-we-do-main">
                          <img src={IMAGES.AboutMeeting} alt="" />
                          <div className="what-we-do-content">
                            <h5>Meeting international standards</h5>
                            <p>
                              We have acquired international standard
                              certifications (ISO) for processes
                            </p>
                          </div>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="what-we-do-wrapper">
                      <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        offset={50}
                      >
                        <div className="what-we-do-main">
                          <img src={IMAGES.AboutClient} alt="" />
                          <div className="what-we-do-content">
                            <h5> Trusted by Clients</h5>
                            <p>
                              We have vast global clientele that trust our way
                              of service delivery and support.
                            </p>
                          </div>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="what-we-do-wrapper">
                      <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        offset={50}
                      >
                        <div className="what-we-do-main">
                          <img src={IMAGES.AboutLoyalty} alt="" />
                          <div className="what-we-do-content">
                            <h5> No compromise on loyalty and trust</h5>
                            <p>
                              We never compromise on loyalty and trust that
                              connect us with our clients
                            </p>
                          </div>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="what-we-do-wrapper">
                      <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        offset={50}
                      >
                        <div className="what-we-do-main">
                          <img src={IMAGES.AboutQuality} alt="" />
                          <div className="what-we-do-content">
                            <h5>Quality Management</h5>
                            <p>
                              Every product and services we deliver are of
                              highest standards.
                            </p>
                          </div>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Challenge cards */}
        <div>
          <div className="about-challenge-cards">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-1">
                <div className="about-feature-img">
                  <div className="img-box-wrap">
                    <div className="box-img"></div>
                    <div className="default-img">
                      <img
                        className="img-fluid"
                        src={IMAGES.AboutWhatWeCanDo}
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <p className="heading">What we can do ?</p>
                      <div className="about-feature-text">
                        We put a strong focus on the needs of your business to
                        figure out solutions that best fits your demand and nail
                        it.
                      </div>
                    </div>
                    <div href="/aboutus" className="box-img-arrow">
                      <span className="btn-text">Discover Now</span>
                      <FiArrowRight size={15} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-1">
                <div className="about-feature-img">
                  <div className="img-box-wrap">
                    <div className="box-img"></div>
                    <div className="default-img">
                      <img
                        className="img-fluid"
                        src={IMAGES.AboutBecomeOurPartners}
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <p className="heading">Become Our Parners ?</p>
                      <div className="about-feature-text">
                        Our preventive and progressive approach will help you
                        take the lead while addressing possible threats in
                        managing data.
                      </div>
                    </div>
                    <div href="/aboutus" className="box-img-arrow">
                      <span className="btn-text">Discover Now</span>
                      <FiArrowRight size={15} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-1">
                <div className="about-feature-img">
                  <div className="img-box-wrap">
                    <div className="box-img"></div>
                    <div className="default-img">
                      <img
                        className="img-fluid"
                        src={IMAGES.AboutNeedAHand}
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <p className="heading">Need A Hand ?</p>
                      <div className="about-feature-text">
                        Our support team is available 24/7 a day, 7 days a week
                        and can get ready for solving any of your situational
                        rising problems.
                      </div>
                    </div>
                    <div href="/aboutus" className="box-img-arrow">
                      <span className="btn-text">Discover Now</span>
                      <FiArrowRight size={15} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-text">
            Challenges are just opportunities in disguise.{" "}
            <a>Take the challenge!</a>
          </div>
        </div>

        {/* Let’s Get Your Project Started */}
        <div className="Apportunities">
          <div className="container-fluid aboutSection">
            <div className="row ">
              <div className="col-lg-6 col-md-6 aboutLeft">
                <h3 className="title">
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    Let’s Get Your Project Started
                  </AnimationOnScroll>
                </h3>
                <p>
                  We will help you to achieve your goals and to grow your
                  business.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 aboutRight">
                <div className="btnQuatation">
                  <button className="animated-button1" onClick={()=>navigate("/contact")}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <p>Meet Now </p>
                    <MdArrowForward size={20} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
