import React from "react";
import "../Assets/Style/Staffing.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { IMAGES } from "../Utils";
const Staffing = () => {
  return (
    <>
      <section className="">
        <div className="page-title">
          <div className="pattern-layer"></div>
          <div className="auto-container">
            <div className="content-box">
              <div className="title-box centred">
                <h1>Our Solutions</h1>
                <p>Smart staffing solutions for every stage of growth.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* service-section */}
      <section className="service-section service-page">
        <div className="anim-icon">
          <div
            className="icon-1"
            // style="background-image: url(http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/09/anim-icon-1.png);"
          ></div>
          <div className="icon-2"></div>
        </div>
        <div className="auto-container">
          <div className="sec-title centred">
            <span className="top-title">Solutions We Provide</span>
            <h2>Inspiring Staffing Solutions</h2>
            <p>
              Transforming hiring into a strategic advantage
              <br></br>We deliver tailored talent that drives results and
              innovation for your business
            </p>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div className="service-block-one">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      width="370"
                      height="300"
                      src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-1.jpg"
                      className="attachment-eazyrecruitz_370x300 size-eazyrecruitz_370x300 wp-post-image"
                      alt=""
                      loading="lazy"
                      srcset="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-1.jpg 370w, http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-1-300x243.jpg 300w"
                      sizes="(max-width: 370px) 100vw, 370px"
                    />
                  </figure>
                  <div className="lower-content">
                    <div className="content-box">
                      <div className="inner">
                        <figure className="icon-box">
                          <img
                            src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/Temporary.png"
                            alt="Awesome Image"
                          />
                        </figure>
                        <h4>Temporary Staffing</h4>
                      </div>
                      <div className="link">
                        <a href="/staffing">More Details</a>
                      </div>
                    </div>
                    <div className="overlay-content">
                      <p>
                        Temporary staffing provides skilled personnel on a
                        short-term basis to cover peak workloads, seasonal
                        demands, or special projects.
                      </p>
                      <a href="/staffing">
                        <i className="flaticon-right-arrow"></i>More Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div className="service-block-one">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      width="370"
                      height="300"
                      src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/image_hire-370x300.png"
                      className="attachment-eazyrecruitz_370x300 size-eazyrecruitz_370x300 wp-post-image"
                      alt=""
                      loading="lazy"
                      srcset="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/image_hire-370x300.png 370w, http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/image_hire-442x357.png 442w"
                      sizes="(max-width: 370px) 100vw, 370px"
                    />
                  </figure>
                  <div className="lower-content">
                    <div className="content-box">
                      <div className="inner">
                        <figure className="icon-box">
                          <img
                            src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/Direct_hire.png"
                            alt="Awesome Image"
                          />
                        </figure>
                        <h4>Direct Hire</h4>
                      </div>
                      <div className="link">
                        <a href="/staffing"> More Details</a>
                      </div>
                    </div>
                    <div className="overlay-content">
                      <p>
                        Direct hire staffing connects companies with qualified
                        candidates for permanent positions.Through a focused
                        recruitment process, we help you secure top talent that
                        aligns with your company culture and long-term goals.
                      </p>
                      <a href="/staffing">
                        More Details
                        <i className="flaticon-right-arrow"></i>More Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div className="service-block-one">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      width="370"
                      height="300"
                      src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-3.jpg"
                      className="attachment-eazyrecruitz_370x300 size-eazyrecruitz_370x300 wp-post-image"
                      alt=""
                      loading="lazy"
                      srcset="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-3.jpg 370w, http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-3-300x243.jpg 300w"
                      sizes="(max-width: 370px) 100vw, 370px"
                    />
                  </figure>
                  <div className="lower-content">
                    <div className="content-box">
                      <div className="inner">
                        <figure className="icon-box">
                          <img
                            src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/Contract.png"
                            alt="Awesome Image"
                          />
                        </figure>
                        <h4>Contract Staffing</h4>
                      </div>
                      <div className="link">
                        <div className="link">
                          <a href="/staffing"> More Details</a>
                        </div>
                      </div>
                    </div>
                    <div className="overlay-content">
                      <p>
                        Contract staffing offers a flexible workforce solution,
                        providing skilled professionals for specific project
                        durations.
                      </p>
                      <div className="link">
                        <a href="/staffing"> More Details</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div className="service-block-one">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      width="370"
                      height="300"
                      src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-4.jpg"
                      className="attachment-eazyrecruitz_370x300 size-eazyrecruitz_370x300 wp-post-image"
                      alt=""
                      loading="lazy"
                      srcset="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-4.jpg 370w, http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-4-300x243.jpg 300w"
                      sizes="(max-width: 370px) 100vw, 370px"
                    />
                  </figure>
                  <div className="lower-content">
                    <div className="content-box">
                      <div className="inner">
                        <figure className="icon-box">
                          <img
                            src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/training.png"
                            alt="Awesome Image"
                          />
                        </figure>
                        <h4>Payrolling Services</h4>
                      </div>
                      <div className="link">
                        <div className="link">
                          <a href="/staffing"> More Details</a>
                        </div>
                      </div>
                    </div>
                    <div className="overlay-content">
                      <p>
                        With payrolling services, we handle all
                        employment-related administration for your contingent
                        workforce, including payroll processing, compliance, and
                        benefits, so you can focus on core business activities.
                      </p>
                      <div className="link">
                        <a href="/staffing"> More Details</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div className="service-block-one">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      width="370"
                      height="300"
                      src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-5.jpg"
                      className="attachment-eazyrecruitz_370x300 size-eazyrecruitz_370x300 wp-post-image"
                      alt=""
                      loading="lazy"
                      srcset="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-5.jpg 370w, http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-5-300x243.jpg 300w"
                      sizes="(max-width: 370px) 100vw, 370px"
                    />
                  </figure>
                  <div className="lower-content">
                    <div className="content-box">
                      <div className="inner">
                        <figure className="icon-box">
                          <img
                            src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/Execitive_serach.png"
                            alt="Awesome Image"
                          />
                        </figure>
                        <h4>Training & Development</h4>
                      </div>
                      <div className="link">
                        <div className="link">
                          <a href="/staffing"> More Details</a>
                        </div>
                      </div>
                    </div>
                    <div className="overlay-content">
                      <p>
                        Our training solutions are designed to enhance the
                        skills of your workforce, whether they are new hires or
                        existing employees, ensuring they are equipped with the
                        knowledge and abilities needed to thrive in their roles.
                      </p>
                      <div className="link">
                        <a href="/staffing"> More Details</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div className="service-block-one">
                <div className="inner-box">
                  <figure className="image-box">
                    <img
                      width="370"
                      height="300"
                      src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-6.jpg"
                      className="attachment-eazyrecruitz_370x300 size-eazyrecruitz_370x300 wp-post-image"
                      alt=""
                      loading="lazy"
                      srcset="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-6.jpg 370w, http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/service-6-300x243.jpg 300w"
                      sizes="(max-width: 370px) 100vw, 370px"
                    />
                  </figure>
                  <div className="lower-content">
                    <div className="content-box">
                      <div className="inner">
                        <figure className="icon-box">
                          <img
                            src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/Pay_rolling.png"
                            alt="Awesome Image"
                          />
                        </figure>
                        <h4>Executive Search</h4>
                      </div>
                      <div className="link">
                        <div className="link">
                          <a href="/staffing"> More Details</a>
                        </div>
                      </div>
                    </div>
                    <div className="overlay-content">
                      <p>
                        Our executive search service specializes in recruiting
                        high-level leaders who can drive organizational success.
                        We identify, evaluate, and secure top-tier talent for
                        key positions to help shape the future of your business.
                      </p>
                      <div className="link">
                        <a href="/staffing"> More Details</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* service-section */}
      {/* hiring-section */}
      <section className="hiring-section">
        <div className="image-layer">
          <figure className="image-1">
            <img
              src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/09/hiring-1.png"
              alt="Awesome Image"
            />
          </figure>
          <figure className="image-2">
            <img
              src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/09/hiring-2.png"
              alt="Awesome Image"
            />
          </figure>
        </div>
        <div className="outer-container clearfix">
          <div className="left-column pull-left clearfix">
            <div className="inner-box pull-right">
              <div className="icon-box">
                {/* <i className="icon flaticon-factory"></i> */}
                <img src={IMAGES.factory} />
              </div>
              <h2>Industries Hiring</h2>
              <p>
                Explore a diverse range of industries actively seeking top
                talent to drive innovation and growth.
              </p>
              <a href="http://www.el.commonsupport.com/newwp/eazyrecruitz/direct-hire/">
                Industries
              </a>
            </div>
          </div>
          <div className="right-column pull-right clearfix">
            <div className="inner-box pull-left">
              <div className="icon-box">
                <img src={IMAGES.workingMan} />
              </div>
              <h2>Professions Hiring</h2>
              <p>
                Discover the professions in high demand, where skilled
                candidates can thrive and make an impact.
              </p>
              <a href="http://www.el.commonsupport.com/newwp/eazyrecruitz/contract-to-hire/">
                Professions
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* hiring-section */}

      {/* section 4 */}

      <section className="recruitment-technology">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <figure className="image-box js-tilt clearfix recruiterInfoLeftFigure">
                <img
                  src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/recruitment-1.png"
                  alt="Awesome Image"
                />
                <div className="js-tilt-glare">
                  <div className="js-tilt-glare-inner"></div>
                </div>
              </figure>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div id="content_block_4">
                <div className="content-box">
                  <div className="sec-title">
                    <span className="top-title">Recruitment technologies</span>
                    <h2>Solving Recruitment Using Technology</h2>
                    <p>
                      Transforming recruitment with technology, DThrill
                      streamlines the hiring process for efficiency and
                      precision. Experience a smarter way to connect with top
                      talent.
                    </p>
                  </div>
                  <div className="inner-box">
                    <div
                      className="single-item wow fadeInRight animated animated animated animate__fadeInRight"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"

                      // style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInRight;"
                    >
                      <AnimationOnScroll animateIn="animate__fadeInRight">
                        <div className="inner">
                          <figure className="icon-box">
                            <img
                              src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/icon-7.png"
                              alt="Awesome Image"
                            />
                          </figure>
                          <h3>
                            <span>01</span>
                            <a href="http://www.el.commonsupport.com/newwp/eazyrecruitz/contract-to-hire/">
                              Sourcing the Best
                              <i className="flaticon-right-arrow"></i>
                            </a>
                          </h3>
                          <p>
                            Using AI tools to find and attract top candidates
                            quickly.
                          </p>
                        </div>
                      </AnimationOnScroll>
                    </div>
                    <div
                      className="single-item wow fadeInRight animated animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                      // style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInRight;"
                    >
                      <AnimationOnScroll animateIn="animate__fadeInRight">
                        <div className="inner">
                          <figure className="icon-box">
                            <img
                              src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/icon-8.png"
                              alt="Awesome Image"
                            />
                          </figure>
                          <h3>
                            <span>02</span>
                            <a href="http://www.el.commonsupport.com/newwp/eazyrecruitz/direct-hire/">
                              Streamlined Hiring Processes
                              <i className="flaticon-right-arrow"></i>
                            </a>
                          </h3>
                          <p>
                            Automating recruitment steps to make hiring faster
                            and easier.
                          </p>
                        </div>
                      </AnimationOnScroll>
                    </div>
                    <div
                      className="single-item wow fadeInRight animated animated animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms"
                      // style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInRight;"
                    >
                      <AnimationOnScroll animateIn="animate__fadeInRight">
                        <div className="inner">
                          <figure className="icon-box">
                            <img
                              src="http://www.el.commonsupport.com/newwp/eazyrecruitz/wp-content/uploads/2020/08/icon-9.png"
                              alt="Awesome Image"
                            />
                          </figure>
                          <h3>
                            <span>03</span>
                            <a href="http://www.el.commonsupport.com/newwp/eazyrecruitz/training/">
                              Partners in Team Building
                              <i className="flaticon-right-arrow"></i>
                            </a>
                          </h3>
                          <p>
                            Working with you to understand your needs and find
                            the right fit for your team.
                          </p>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 4 */}
    </>
  );
};
export default Staffing;
